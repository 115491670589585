<template>
    <div class="kanban-board">
        <div
            v-for="(column, columnIndex) in columns"
            :key="column.id"
            class="kanban-column"
            @dragover.prevent
            @drop="onDrop(columnIndex)"
        >

            <h2>
                {{ column.name }}
                <span :class="'badge bg-' + column.color">{{ column.tasks.length }}</span>
            </h2>
            
            <div
                v-for="(task, taskIndex) in column.tasks"
                :key="task.id"
                class="kanban-card"
                draggable="true"
                @dragstart="onDragStart(columnIndex, taskIndex)"
                @click="open(task)"
            >
                {{ task.title }}
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            draggedTask: null, // Інформація про завдання, яке перетягується
        };
    },
    props: ['columns'],
    methods: {
        open(task) {
            if(task.testingId) {
                alert('openBugReport')
            } else {
                alert('openTask')
            }
        },
        // Початок перетягування
        onDragStart(columnIndex, taskIndex) {
            this.draggedTask = { columnIndex, taskIndex };
        },

        // Подія при скиданні завдання в нову колонку
        onDrop(newColumnIndex) {
            if (this.draggedTask !== null) {
                const { columnIndex, taskIndex } = this.draggedTask;

                // Витягуємо завдання зі старої колонки
                const task = this.columns[columnIndex].tasks.splice(taskIndex, 1)[0];

                // Додаємо завдання в нову колонку
                this.columns[newColumnIndex].tasks.push(task);

                // Скидаємо draggedTask
                this.draggedTask = null;
            }
        }
    }
};
</script>

<style scoped>
    .kanban-board {
        display: flex;
        gap: 20px;
    }

    .kanban-column {
        width: 200px;
        padding: 10px;
        /* background-color: #f4f4f4; */
        border-radius: 5px;
        /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
    }

    .kanban-column h2 {
        text-align: start;
        font-size: 18px;
        margin-bottom: 10px;
    }

    .kanban-card {
        padding: 10px;
        margin: 5px 0;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 3px;
        cursor: pointer;
    }

    .kanban-card:active {
        cursor: grabbing;
    }
</style>