<template>
  <div class="row" style="height: 100%;">
    <div class="col-md-3">
      <div class="card" style="height: 100%; gap: 20px; padding: 20px">
        <h2>Workspaces</h2>
        <div class="list-group" id="list-tab" role="tablist" v-for="items, index in this.projects">
          <a 
            v-if="items.teamLead === false"
            class="list-group-item list-group-item-action " 
            :class="this.currentIdx === index ? 'active' : ''" 
            :id="items.name + `-list`" 
            data-bs-toggle="list" 
            :href="`#` + items.name" 
            role="tab" 
            :aria-controls="items.name"
            @click="this.currentIdx = index"
          >
            {{items.name}}
          </a>
          <template v-else>
            <a 
              class="list-group-item list-group-item-action" 
              data-bs-toggle="collapse" 
              href="#collapseExample" 
              role="button" 
              aria-expanded="false" 
              aria-controls="collapseExample"
            >
              {{items.name}}
              <span class="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
                <span class="visually-hidden">New alerts</span>
              </span>
            </a>
            <div class="collapse" id="collapseExample">
              <div class="card card-body">
                <a 
                  class="list-group-item list-group-item-action " 
                  :class="this.currentIdx === index ? 'active' : ''" 
                  :id="items.name + `-list`" 
                  data-bs-toggle="list" 
                  :href="`#` + items.name" 
                  role="tab" 
                  :aria-controls="items.name"
                  @click="this.currentIdx = index"
                >
                  Завдання
                </a>
                
                <a 
                  @click="this.currentIdx = 'bugReports'"
                  class="list-group-item list-group-item-action " 
                  :class="this.currentIdx === 'bugReports' ? 'active' : ''"
                  id="list-bugReports-list" 
                  data-bs-toggle="list" 
                  href="#list-bugReports" 
                  role="tab" 
                  aria-controls="list-bugReports"
                >
                  Баг-репорти
                </a>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="col-md-9">
      <div class="tab-content" id="nav-tabContent">
        <div 
          class="tab-pane fade "  
          v-for="items, index in this.projects" 
          :class="this.currentIdx === index ? 'show active' : ''" 
          :id="items.name" 
          role="tabpanel" 
          :aria-labelledby="items.name + `-list`"
        >
          <!-- {{ items }} -->
          <Board :columns="items.columns"/>
        </div>

        <div 
          class="tab-pane fade " 
          :class="this.currentIdx === 'bugReports' ? 'show active' : ''" 
          id="list-bugReports" 
          role="tabpanel" 
          aria-labelledby="list-bugReports-list"
        >
          <Board :columns="this.columnsWithBugReports"/>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
  import Board from './board.vue';

  export default {
    data() {
      return {
        currentIdx: 1,
        columnsWithBugReports: [
          {
            id: 1,
            color: 'primary',
            name: "To Do",
            tasks: [
              {id: 1, title: "Баг-репорт 1 з тестування 1", testingId: 1},
              {id: 2, title: "Баг-репорт 2 з тестування 2", testingId: 2},
              {id: 3, title: "Баг-репорт 3 з тестування 2", testingId: 2}
            ]
          },
        ],
        projects: [
          { 
            id: 1, 
            teamLead: false,
            name: 'POS-terminal',
            // Дані для канбан-дошки
            columns: [
              {
                id: 1,
                color: 'primary',
                name: "To Do",
                tasks: [
                  { id: 1, title: "Task 5" },
                  { id: 2, title: "Task 2" }
                ]
              },
              {
                id: 2,
                color: 'primary',
                name: "In Progress",
                tasks: [
                  { id: 3, title: "Task 3" }
                ]
              },
              {
                id: 3,
                color: 'primary',
                name: "Done",
                tasks: [
                  { id: 4, title: "Task 4" }
                ]
              }
            ],
          },
          { 
            id: 2, 
            name: 'Admin',
            teamLead: true,
            columns: [
              {
                color: 'primary',
                id: 1,
                name: "To Do",
                tasks: [
                  { id: 1, title: "Task 1" },
                  { id: 2, title: "Task 2" }
                ]
              },
              {
                id: 2,
                color: 'primary',
                name: "In Progress",
                tasks: [
                  { id: 3, title: "Task 3" }
                ]
              },
              {
                color: 'primary',
                id: 3,
                name: "Done",
                tasks: [
                  { id: 4, title: "Task 4" }
                ]
              }
            ],
          },
        ]
      }
    },
    components: {
      Board
    }
  }
</script>
